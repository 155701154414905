import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import Shirt from './Shirt';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
var firebaseConfig = {
    apiKey: 'AIzaSyAs_A--fr0_ztbTLs7CY86ybB0v2U6Zz44',
    authDomain: 'ykvlv-1.firebaseapp.com',
    projectId: 'ykvlv-1',
    storageBucket: 'ykvlv-1.appspot.com',
    messagingSenderId: '201270312097',
    appId: '1:201270312097:web:b9d49c999fbf3c51f9c4e0',
    measurementId: 'G-ZGF2N31ZY5',
};
var app = initializeApp(firebaseConfig);
var analytics = getAnalytics(app);
var root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(BrowserRouter, { children: _jsxs(Routes, { children: [_jsx(Route, { path: '/', element: _jsx(App, {}) }), _jsx(Route, { path: '/shirt/:shirtId', element: _jsx(Shirt, {}) })] }) }));
