var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import './Shirt.css';
import '@google/model-viewer/dist/model-viewer';
import ykvlv_logo from './imgs/ykvlv.svg';
import twitter_logo from './imgs/twitter.svg';
import inst_logo from './imgs/instagram.svg';
import ton_logo from './imgs/ton.svg';
import telegram_logo from './imgs/telegram.svg';
import Airtable from 'airtable';
import { useParams } from 'react-router-dom';
import Confetti from './Confetti';
var AIRTABLE_TOKEN = process.env.AIRTABLE_TOKEN;
var AIRTABLE_BASE = (_a = process.env.AIRTABLE_BASE) !== null && _a !== void 0 ? _a : '';
function Menu() {
    return _jsx("div", { className: 'menu', children: _jsx("a", { href: 'https://ykvlv.ai/', children: _jsx("img", { src: ykvlv_logo, alt: 'YKVLV', style: { height: 20 } }) }) });
}
function ShirtModel(_a) {
    var _b, _c;
    var shirtInfo = _a.shirtInfo;
    var modelLink = (_b = shirtInfo.model_3d) !== null && _b !== void 0 ? _b : 'https://app.ykvlv.ai/punks/ykvlv_3d/' + shirtInfo.shirt + '.glb';
    var photoLink = (_c = shirtInfo.photo) !== null && _c !== void 0 ? _c : 'https://app.ykvlv.ai/punks/ykvlv_3d/' + shirtInfo.shirt + '.png';
    return _jsx("div", { children: _jsx("model-viewer", { bounds: 'tight', src: modelLink, ar: true, "ar-modes": 'webxr scene-viewer quick-look', "camera-controls": true, "environment-image": 'neutral', poster: photoLink, "shadow-intensity": '1', autoplay: true, style: { width: 300, height: 400, margin: 'auto' } }) });
}
function ShirtInfo() {
    var options = [
        'wash dark colours separately',
        'wash and dry inside out',
        'colours loss may occur during initial washes do not wring',
        'do not tumble dry',
        'dry out of direct heat and sunlight',
        'iron on reverse',
        'QC PASS 30',
        // 'UK HENBURY LTD, SCOTLAND, EH54 5FD'
    ];
    return _jsxs("div", { children: [_jsx("div", { className: 'param_key', children: "Shirt information" }), _jsx("div", { className: 'param_value', style: { fontSize: 15 }, children: _jsx("ul", { children: options.map(function (option, id) { return _jsx("li", { children: option }, id); }) }) })] });
}
function SocialLink(_a) {
    var link = _a.link, logo = _a.logo, linkText = _a.linkText;
    if (!link) {
        return null;
    }
    var text = linkText !== null && linkText !== void 0 ? linkText : link.split('/').pop();
    return _jsxs("a", { href: link, style: { textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }, children: [_jsx("img", { src: logo, alt: 'twitter', style: { height: 30 } }), _jsx("div", { className: 'param_value', children: text })] });
}
function OwnerInfo(_a) {
    var shirtInfo = _a.shirtInfo;
    return _jsxs("div", { style: { marginBottom: 20 }, children: [_jsxs("div", { className: 'param_key', style: { textTransform: 'capitalize', textAlign: 'center', marginBottom: '20px' }, children: ["Shirt #", shirtInfo.number, ". ", shirtInfo.shirt] }), _jsxs("div", { style: { display: 'flex' }, children: [_jsx("div", { className: 'param_key', children: "Owner" }), _jsx("div", { className: 'param_value', style: { textTransform: 'capitalize' }, children: shirtInfo.name })] }), _jsx(SocialLink, { link: shirtInfo.twitter, logo: twitter_logo }), _jsx(SocialLink, { link: shirtInfo.instagram, logo: inst_logo }), _jsx(SocialLink, { link: shirtInfo.telegram, logo: telegram_logo }), _jsx(SocialLink, { link: shirtInfo.nft, logo: ton_logo, linkText: 'NFT' }), _jsxs("div", { style: { display: 'flex', marginTop: 20 }, children: [_jsx("div", { className: 'param_key', children: "Date created" }), _jsx("div", { className: 'param_value', children: shirtInfo.created })] })] });
}
function Shirt() {
    var params = useParams();
    var shirtId = params.shirtId;
    var base = new Airtable({ apiKey: AIRTABLE_TOKEN }).base(AIRTABLE_BASE);
    var _a = useState(null), shirtInfo = _a[0], setShirtInfo = _a[1];
    useEffect(function () {
        console.log('SHIRT ID', shirtId);
        // base('shirts').select({
        //   filterByFormula: 'number=' + shirtId,
        //   maxRecords: 1,
        // }).firstPage().then(record => {
        //   // console.log(record)
        //   if (record.length > 0) {
        //     setShirtInfo(record[0].fields as ShirtInfoType)
        //   }
        // })
        base('shirts').find('rec' + shirtId, function (err, record) {
            if (err) {
                console.error(err);
                return;
            }
            if (record) {
                setShirtInfo(record.fields);
            }
        });
    }, []);
    if (!shirtInfo) {
        return null;
    }
    return (_jsxs("div", { className: 'container', children: [_jsx(Menu, {}), _jsx("div", { className: 'header', children: shirtInfo.message }), _jsxs("div", { style: { justifyContent: 'center' }, children: [_jsx(ShirtModel, { shirtInfo: shirtInfo }), _jsx(OwnerInfo, { shirtInfo: shirtInfo }), _jsx(ShirtInfo, {})] }), _jsx(Confetti, { showConfetti: true })] }));
}
export default Shirt;
