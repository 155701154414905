var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useRef } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
var canvasStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: 'transparent',
};
export default function Confetti(_a) {
    var showConfetti = _a.showConfetti, onFinish = _a.onFinish;
    var refAnimationInstance = useRef(null);
    var getInstance = useCallback(function (instance) {
        refAnimationInstance.current = instance;
    }, []);
    var makeShot = useCallback(function (particleRatio, options) {
        var _a;
        (_a = refAnimationInstance.current) === null || _a === void 0 ? void 0 : _a.call(refAnimationInstance, __assign(__assign({}, options), { origin: { y: 0.7 }, particleCount: Math.floor(200 * particleRatio) }));
    }, []);
    var fire = useCallback(function () {
        makeShot(0.25, {
            spread: 26,
            startVelocity: 55,
        });
        makeShot(0.2, {
            spread: 60,
        });
        makeShot(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8,
        });
        makeShot(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2,
        });
        makeShot(0.1, {
            spread: 120,
            startVelocity: 45,
        });
    }, [makeShot]);
    useEffect(function () {
        if (showConfetti) {
            fire();
            onFinish === null || onFinish === void 0 ? void 0 : onFinish();
        }
    }, [showConfetti, fire, onFinish]);
    return _jsx(ReactCanvasConfetti, { refConfetti: getInstance, style: canvasStyles });
}
